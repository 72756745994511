@media (max-width: 768px) {

.header-img{
    width: 15vh;
    margin-left: 2vh !important;

    
}

.right-icons{
    margin-top: -6vh;
}



}



.header-img{

    margin-left: 12vh;
}