.why-choose-me .card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .why-choose-me .card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .why-choose-me .icon {
    font-size: 2.5rem;
    color: #a4c639;
  }
  