@media (max-width: 768px) {
  .Modern-Slider{
        width: 49vh !important;
       
    }   
    .Modern-Slider{
        width: 49.3vh !important;  
    }  

    .testimonials{
        width:49vh ;
    }
}

 @media (max-width: 576px) {
    .inline {
      flex-direction: column; 
      align-items: center;
      margin-left: -3vh;
    }
    .more-info .left-image img {
		width: 42.7vh ;
		height: 40vh;
		object-fit: cover;
		
	}

    .count-digit {
      font-size: 2.5rem; 
    }
  
    .count-sign {
      font-size: 2rem; 
      margin-left: 0;
      margin-top: -5.5vh; 
      margin-left: 13vh;
    }
  }